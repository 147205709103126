import { action } from "easy-peasy";

const actions = {
  setCheckedOutToolListAction: action((state, checkedOutToolList) => {
    state.tool.checkedOutToolList = checkedOutToolList;
  }),
  setToolIdInputAction: action((state, toolIdInput) => {
    state.tool.toolIdInput = toolIdInput.toUpperCase();
  }),
  showCheckInToolModalAction: action((state, showCheckInToolModal) => {
    state.tool.showCheckInToolModal = showCheckInToolModal;
  }),
  showCheckOutToolModalAction: action((state, showCheckOutToolModal) => {
    state.tool.showCheckOutToolModal = showCheckOutToolModal;
  }),
  setValidToolsAction: action((state, tools) => {
    state.tool.validTools = tools;
  }),
  addValidToolsAction: action((state, tool) => {
    state.tool.validTools = [...state.tool.validTools, tool];
  }),
  setToolSearchResultsAction: action((state, toolSearchResults) => {
    state.tool.toolSearchResults = toolSearchResults;
  }),
  setToolToCheckOutAction: action((state, toolToCheckOut) => {
    state.tool.toolToCheckOut = toolToCheckOut;
  }),
  showToolCalibrationExpirationWarningModalAction: action(
    (state, showModal) => {
      state.tool.showToolCalibrationExpirationWarningModal = showModal;
    }
  ),
  setShowAllToolsForTaskAction: action((state, show) => {
    state.tool.showAllToolsForTask = show;
  }),
};

export default actions;
