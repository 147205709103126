import Modal from "./Modal";
import styled from "styled-components/native";
import Center from "../../presentational/Center";
import Button from "../Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import Gap from "../../presentational/Gap";
import { remToDp } from "../../../utilities/responsive";

const SText = styled.Text`
  color: red;
  font-size: ${remToDp(3)}px;
`;

function ToolCalibrationExpirationWarningModal(props) {
  const {
    tool: { showToolCalibrationExpirationWarningModal },
    auth: { acumaticaConfig },
  } = useStoreState((state) => state);

  const { showToolCalibrationExpirationWarningModalAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Modal
      visible={showToolCalibrationExpirationWarningModal}
      onRequestClose={() =>
        showToolCalibrationExpirationWarningModalAction(false)
      }
      {...props}
    >
      <Center>
        <SText>Warning!</SText>
        <SText>
          This tool is within{" "}
          {acumaticaConfig.kioskConfig.cfToolCalibrationGraceDays || 5} days
          from the calibration expiring.
        </SText>
        <Gap />
        <Button.CloseToolCalibrationExpirationWarningModal />
      </Center>
    </Modal>
  );
}

export default ToolCalibrationExpirationWarningModal;
