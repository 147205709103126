import { useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { format } from "date-fns";

function CheckedOutToolList() {
  const { checkedOutToolList, showAllToolsForTask } = useStoreState(
    (state) => state.tool
  );
  return (
    <List
      width={
        showAllToolsForTask
          ? ["40%", "12%", "28%", "20%"]
          : ["13%", "31%", "12%", "28%", "16%"]
      }
      tableHead={(showAllToolsForTask ? [] : ["Employee ID"]).concat([
        "Task ID",
        "Tool ID",
        "Description",
        "Date",
      ])}
      tableRows={checkedOutToolList.map((tool) =>
        (showAllToolsForTask ? [] : [tool.employeeId]).concat([
          tool.workTaskId,
          tool.toolId,
          tool.toolDescription,
          format(new Date(), "yyyy-MM-dd p"),
        ])
      )}
    />
  );
}

export default CheckedOutToolList;
