import { axios } from ".";

const toolAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/tool`,
});

function getCheckedOutToolListAPICall({
  employeeId,
  worktaskId,
  allToolsbyTask,
}) {
  return toolAxios({
    method: "get",
    url: `/list/checkedout?employeeId=${employeeId}&worktaskId=${worktaskId}&allToolsbyTask=${allToolsbyTask}`,
  });
}

function checkInToolAPICall(toolId) {
  return toolAxios({
    method: "put",
    url: "/checkin",
    data: { toolId },
  });
}

function checkOutToolAPICall(data) {
  return toolAxios({
    method: "put",
    url: "/checkout",
    data,
  });
}

function searchToolsAPICall(toolId) {
  return toolAxios({
    method: "get",
    url: `/search/${toolId}`,
  });
}

export {
  toolAxios,
  getCheckedOutToolListAPICall,
  checkInToolAPICall,
  checkOutToolAPICall,
  searchToolsAPICall,
};
