function isWithinDays(date1, date2, daysLimit) {
  // Convert both dates to milliseconds
  const diffInMilliseconds = Math.abs(new Date(date2) - new Date(date1));

  // 7 days in milliseconds
  const daysLimitInMilliseconds = daysLimit * 24 * 60 * 60 * 1000;

  // Check if the difference is within days limit
  return diffInMilliseconds <= daysLimitInMilliseconds;
}

export { isWithinDays };
