import styled from "styled-components/native";
import Text from "../Text";
import { remToDp } from "../../../utilities/responsive";

const Td = styled(Text)`
  flex: auto;
  text-align: center;
  padding: ${remToDp(1)}px;
  border: ${(props) => (props.border ? "1px solid #dee2e6" : "0")};
  font-size: ${remToDp(1.25)}px;
`;

export default Td;
