import { thunk } from "easy-peasy";
import { getTaskAPICall } from "../../api/task";
import { getCheckedOutToolListAPICall } from "../../api/tool";
import { checkToolCheckoutLimit } from "../../utilities/tool";

const thunks = {
  scanTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const { auth, employee, task, tool } = helpers.getState();

      checkToolCheckoutLimit(helpers);

      let response = await getTaskAPICall(task.taskIdInput);
      actions.setScannedTaskAction(response.data);

      response = await getCheckedOutToolListAPICall({
        ACMWCustomerKey: auth.acumaticaConfig.acmCompanyKey,
        employeeId: employee.scannedEmployee.AcctCD,
        worktaskId: response.data.TaskCD,
        allToolsbyTask: tool.showAllToolsForTask,
      });
      actions.setCheckedOutToolListAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response ? err.response.data : err.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
