import Page from "../containers/Page";
import React from "react";
import Input from "../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components/native";
import Card from "../presentational/Card";
import Gap from "../presentational/Gap";
import List from "../containers/List";
import Button from "../containers/Button";
import Modal from "../containers/Modal";
import Row from "../presentational/Row";
import Col from "../presentational/Col";
import { Switch } from "react-native-elements";
import Text from "../presentational/Text";

const Container = styled.View`
  padding: 32px;
`;

const ToolInputRow = styled(Row)`
  align-items: center;
`;

const ToolInputCol = styled(Col)`
  padding: 0;
`;

const ButtonCol = styled(Col)`
  align-items: center;
  flex: 1;
  flex-direction: row;
`;
ButtonCol.Container = styled.View`
  width: 100%;
`;

export default function Home() {
  const { employee, task, tool } = useStoreState((state) => state);

  const {
    scanEmployeeThunk,
    scanTaskThunk,
    setBadgeIdAction,
    setTaskIdInputAction,
    setToolIdInputAction,
    fastCheckOutToolThunk,
    setShowAllToolsForTaskAction,
  } = useStoreActions((actions) => actions);

  return (
    <Page.Protected>
      <Container>
        <Card>
          <Row>
            <Col xs={12} md={8}>
              <Input
                label="Employee ID"
                value={employee.badgeId}
                onChangeText={(badgeId) => setBadgeIdAction(badgeId)}
                onSubmitEditing={() => scanEmployeeThunk()}
                placeholder="Enter employee badge ID"
              />

              {employee.scannedEmployee.EmployeeID && (
                <>
                  <Gap />
                  <Row style={{ alignItems: "center" }}>
                    <Col xs={12} md={6}>
                      <Input
                        label="Task ID"
                        value={task.taskIdInput}
                        onChangeText={(taskId) => setTaskIdInputAction(taskId)}
                        onSubmitEditing={() => scanTaskThunk()}
                        placeholder="Enter task ID"
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <Row style={{ alignItems: "center" }}>
                        <Switch
                          value={tool.showAllToolsForTask}
                          onValueChange={(checked) => {
                            setShowAllToolsForTaskAction(checked);
                            scanTaskThunk();
                          }}
                        />
                        <Text> Show All Tools For Task</Text>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>

            <ButtonCol xs={12} md={4}>
              <ButtonCol.Container>
                <Button.ShowCheckInToolPrompt />
              </ButtonCol.Container>
            </ButtonCol>

            <Col xs={12} md={8}>
              {task.scannedTask.TaskCD && (
                <ToolInputRow>
                  <ToolInputCol xs={11} sm={11}>
                    <Input
                      label="Tool ID To Check Out"
                      value={tool.toolIdInput}
                      onChangeText={(toolId) => setToolIdInputAction(toolId)}
                      onSubmitEditing={() => fastCheckOutToolThunk()}
                      placeholder="Enter tool ID to check out"
                    />
                  </ToolInputCol>
                  <ToolInputCol xs={1} sm={1}>
                    <Button.ShowCheckOutToolPrompt />
                  </ToolInputCol>
                </ToolInputRow>
              )}
            </Col>

            <ButtonCol xs={12} md={4}>
              <ButtonCol.Container>
                <Button.ClearInput />
              </ButtonCol.Container>
            </ButtonCol>
          </Row>

          {tool.checkedOutToolList.length > 0 && (
            <>
              <Gap />
              <Gap />
              <List.CheckedOutTool />
            </>
          )}

          <Modal.CheckInTool />
          <Modal.CheckOutTool />
          <Modal.ToolCalibrationExpirationWarning />
        </Card>
      </Container>
    </Page.Protected>
  );
}
